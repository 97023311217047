a {
    text-decoration: none;
}
legend {
    font-size:$h4-font-size;
}

#content-header {
    background-color: $primary;
    height:120px;  /* needed for content-container max-height */
}

#portal-logo img {
    max-height: 65px;
    margin: $spacer 0;
}

#fampass-toolbar {
    background-color: $gray-400;
}

a.btn-portal div.card-img-top {
    height:125px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.blurred {
    color: transparent;
    text-shadow: rgba(0, 0, 0, 0.7) 0px 0px 3px;
}

#portal-membertools-wrapper {
    margin:0;
}

.select2-container {
    display:block;
}

#fampass-content-container {
    background-color:$fampass-content-bg;
    max-height: calc(100vh - 120px);
    overflow-y: auto;

}
.form-label {
    font-size:$small-font-size;
}
.invalid-feedback {
    @extend .alert;
    @extend .alert-danger;

    padding:0.25rem;
}

#content .table .badge {
    font-size:$font-size-base;
    font-weight:400;
    padding:.2rem;
    margin-left: -.2rem;
}

body.portaltype-familienpass,
body.portaltype-folder {
    #fieldset-administration,
    #fieldset-default,
    #fieldset-settings {
        display:none;
    }
    &.userrole-manager #fieldset-administration {
        display:block;
    }
}

.injecting {
    position:relative;

    &::before {
        content: " ";
        position: absolute;
        top: 1rem;
        left: 45%;
        z-index: 1000;
        @extend .spinner-grow;
    }
}

body.template-familienpass #fieldset-settings {
    display:none;
}
