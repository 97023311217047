// These are copied from Bootstrap so that we can use and redefine default values for the theme.
$primary:  #97be0d;
$body-bg: #ffffff;
$fampass-content-bg: #e8e8e8;
$nav-link-color: #000000;
$nav-pills-link-active-bg: #00000099;

// Color system
$white:    #fff;
$gray-100: #f8f8f8;
$gray-200: #e9e9e9;
$gray-300: #dedede;
$gray-400: #cecece;
$gray-500: #adadad;
$gray-600: #6c6c6c;
$gray-700: #494949;
$gray-800: #343434;
$gray-900: #212121;
$black:    #000;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);
// scss-docs-end colors-map

$plone-light-color: $gray-100;
$plone-dark-color: $gray-900;

// Plone specific colors
//colors
$state-draft-color:                 #fab82a; // lime-yellow //draft is visible
$state-pending-color:               #e2e721; // orange
$state-private-color:               #c4183c; // red
$state-internal-color:              #fab82a; // is draft
$state-internally-published-color:  #883dfa; // is intranet

// Plone
$plone-link-color:                  rgb(12,88,171); //plone blue made slightly darker for wcag 2.0
$plone-link-color-on-dark:          lighten($plone-link-color, 25%); //plone blue
//if you need different contrast
$plone-link-color-on-grey:          $plone-link-color; //valid wcag 2.0
$plone-link-hover-color:            darken($plone-link-color, 15%);


$plone-colors: (
  "link-color":                     $plone-link-color,
  "link-color-on-dark":             $plone-link-color-on-dark,
  "link-color-on-grey":             $plone-link-color-on-grey,
  "link-hover-color":               $plone-link-hover-color,
);

$state-colors: (
  "draft":                          $state-draft-color,
  "pending":                        $state-pending-color,
  "private":                        $state-private-color,
  "internal":                       $state-internal-color,
  "internally-published":           $state-internally-published-color,
);
