// set roboto-base-path to barceloneta
$roboto-base-path: "/++theme++barceloneta/roboto/";

// $font-family-sans-serif: "Roboto", sans-serif;
$font-family-sans-serif: Arial, sans-serif;

// $enable-rounded: false;

// need to copy $font-size-base because of import order
$font-size-base: .875rem; // 14px
$small-font-size: .75rem;
$legend-font-size: $small-font-size;
$legend-font-weight: 500;

// badge
// $badge-font-size: $font-size-base;
// $badge-font-weight: 400;
// $badge-padding-x: 0.2rem;
// $badge-padding-y: 0.2rem;

// $h1-font-size: $font-size-base * 1.625;
// $h2-font-size: $font-size-base * 1.375;
// $h3-font-size: $font-size-base * 1.25;
// $h4-font-size: $font-size-base * 1.125;
// $h5-font-size: $font-size-base;
// $h6-font-size: $font-size-base;

// $headings-font-weight: 600;

$input-bg: white;
